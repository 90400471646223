const Urls = {
  api: {
    categories: "/hashtagit/categories",
    category: "/hashtagit/categories/:id",
    account: "/account",
  },
  routes: {
    login: "/login",
    register: "/register",
    logout: "/logout",
    app: "/app",
    account: "/account",
    root: "/",
  },
};

export default Urls;
