export default function Logo() {
  return (
    <h1
      className="text-blue-600"
      style={{ fontFamily: "'Lobster', sans-serif" }}
    >
      HashtagIt
    </h1>
  );
}
